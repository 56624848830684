import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO
      title="Gizlilik Sözleşmesi"
      description="flatart.com.tr gizlilik sözleşmesi"
    />
    <div className="content-page" style={{marginTop:40 }}>
      <div
        className="content container"
      >
        <p>
          Flatart.com.tr internet sitesi üzerinde bulunan tüm içerikler,
          fotoğraflar, tasarımlar, videolar ve bunların düzenlenmeleri
          üzerindeki her türlü fikri ve sınai mülkiyet hakkı, aksi açıkça
          belirtilmediği sürece Flatart Digital Marketing Agency’e aittir.
          İçerikler https://flatart.com.tr/'nin izni olmadan dağıtılamaz, kaynak
          gösterilmeden paylaşılamaz.
        </p>
        <p>
          Flatart.com.tr, hiçbir koşulda, içerikte olabilecek hata veya
          eksiklikler veya web sitesinde bulunan içeriğin kullanımından
          kaynaklanabilecek herhangi bir kayıp veya hasar dahil, fakat bunlarla
          kısıtlı olmamak kaydıyla, içerikten ve ziyaretçi tarafından sunulan
          yanlış bilgiden sorumlu tutulamaz.
        </p>
        <p>
          Flatart.com.tr web sitemizde, kendisinin veya üçüncü kişilerin
          hizmetleri hakkında reklam ve promosyonlar görüntülenebilir. Web
          sitemizde Flatart.com.tr’ye ait olmayan ve faaliyetleriyle ilgili
          bulunmayan diğer web sitelerine yönlendirme yapılabilir. Ziyaretçinin
          bu web sitelerinden herhangi birini ziyaret etmesi halinde üçüncü
          kişilerin kullanımında ve aitliğinde olan bu web sitelerinin politika
          ve uygulamalarından Flatart.com.tr sorumlu değildir.
        </p>
        <p>
          Bu internet sitesinin kullanımı sırasında veya sonrasında oluşabilecek
          herhangi bir performans arızası, hata, eksiklik, kesinti, kusur,
          işletme ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat ve/veya
          sistem arızası sonucu ortaya çıkan doğrudan ya da dolaylı ortaya çıkan
          zarar, ziyan ve masraflar dâhil ancak bunlarla sınırlı olmamak üzere
          hiçbir zarar ve ziyandan, Flatart.com.tr ve/veya çalışanları, bu tür
          bir zarar ve ziyan olasılığından haberdar edilmiş olsalar dahi,
          sorumlu olmazlar. Flatart.com.tr, bu İnternet sitesinde yer alan bütün
          ürün ve hizmetleri, sayfaları, bilgileri, görsel unsurları, içeriği
          önceden bildirimde bulunmadan değiştirme hakkını saklı tutar.
        </p>
        <p>
          Tarafınıza Flatart.com.tr tarafından gönderilen doğrudan pazarlama
          iletilerini almak istemiyorsanız, bu talebinizi, size gönderilen her
          bir iletinin altında yer alan “listeden ayrılma” uzantısı üzerinden
          tarafımıza gönderebilir ya da iletişim bilgilerimizden bize ulaşarak
          talepte bulunabilirsiniz.
        </p>
        <p>
          Flatart.com.tr web sitemizin hukuki şartları ve gizlilik hakkında daha
          fazla bilgi almak, sitemizle ilgili görüş ve eleştirilerinizi
          bildirmek için bize e-posta yoluyla ulaşabilirsiniz.
        </p>
        <h2>Ziyaretçi Bilgileri</h2>
        <p>
          Blogumuzun istatistiklerinin tutulması amacıyla Google’ın bir ürünü
          olan Analytics sistemi kullanılmaktadır. Bu sistem tamamıyla zararsız
          bir sistemdir. Ancak ziyaretçi istatistiklerimizin tutulması amacı
          ile, hangi ziyaretçinin hangi IP adresiyle geldiğini, hangi şehirden
          blogumuza geldiği gibi bilgileri tuttuğunu bildirmek isteriz. Bu
          bilgilerin herhangi bir yerde kullanılmadığını ve kullanılmayacağını
          bildiririz.
        </p>
        <h2>Yayın Yorumları</h2>
        <p>
          Blogumuzdaki içeriklere yorum yapma özelliği, her kullanıcımıza
          açıktır. Buna karşın yapılan yorumlarla ilgili tüm sorumluluk yorumu
          yapan kişiye aittir. Yorumlar denetleme yapılmadan yayınlanmamaktadır.
          Blog yazarına veya diğer kişilere hakaret içerikli yorumlar
          yazıldığında, reklam içerikli linkler verildiğinde bu yorumların
          silindiğini belirtmek isteriz. Bunun yanında yayınlanan yorumlarla
          ilgili, sonrada doğabilecek herhangi yasal bir problem konusunda,
          yorum yapan kişi bu sorumluluktan kaçamayacaktır. Blog
          ziyaretçilerimizin; hakaret içerikli, reklam içerikli ve üçüncü
          kişilerin haklarını gasp edici yorumlarda bulunmamaları faydalarına
          olacaktır.
        </p>
        <h2>Çerezler</h2>
        <p>
          Çerez yada ingilizce kullanımıyla cookies, Flatart.com.tr’yi ziyaret
          ettiğinizde bilgisayarınız veya mobil cihazınıza (akıllı telefon,
          tablet gibi) kaydedilen küçük metin dosyasıdır. Çerezler içerisinde
          size ait bilgileri kayıt eder ve siteyi sonraki ziyaretlerinizde size
          daha iyi bir deneyim sunabilmemiz için yardımcı olur. Çerezler
          aracılığıyla kullandığımız Google Analytics gibi ürünler aracılığıyla
          sizi daha iyi tanımızda yardımcı olur. Böylelikle doğru bir istatistik
          tutabilir size özel geliştirmeler yapabiliriz.
        </p>
        <h2>Hangi Türlerini Kullanıyoruz?</h2>
        <p>
          Oturum çerezleri (session cookies) ve kalıcı çerezler (persistent
          cookies) olmak üzere 2 türde cookies Flatart.com.tr’de
          kullanılmaktadır. Oturum çerezleri siz websitesini tarayıcıdan
          kapatana kadar kullandığınız çerezlerdir, sonrasında silinmektedir.
          Kalıcı çerezler ise daha önceki deneyimlerinizi kayıt olarak tutan ve
          Google Analytics gibi programlar için gerekli çerezlerdir.
          Flatart.com.tr çerez oluşturan 2 araç kullanmaktadır: Google Analytics
          ve AddThis.com
        </p>
      </div>
    </div>
  </Layout>
)

export default About
